.footer {
  width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  padding: 0.5rem 1rem 2rem 1rem;
  box-sizing: border-box;
  margin-top: auto;
  &__item {
    width: 90%;
    height: 2.8rem;
    width: 94%;
    height: 3rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin-bottom: 0.3rem;
  }
  &__image {
    width: 1.5rem;
    width: 1.4rem;
    margin-right: 1rem;
    * {
      fill: #5E0885;
    }
  }
  &__title {
    background: #353535;
    background: #393939;
    width: 100%;
    height: 2.8125rem;
    height: 2.9rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    padding-left: 1.5rem;
    box-sizing: border-box;
    font-weight: bold;
    margin-bottom: 0.7rem;
  }
  &__phone {
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    text-align: center;
    width: 100%;
    padding: 0.5rem 1rem;
    font-weight: bold;
    background: #5E0885;
    color: #fff;
  }
}