.locale {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    background: #393939;
    width: 90%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    margin-bottom: 8px;
  }
  &__item {
    width: 80%;
    height: 2.8rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;
    border: 1px solid #bbb;
    margin-bottom: 4px;
    &_active {
      background: #c9c9c9;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: .7em 1em;
    width: 65%;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    background: #5E0885;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}